import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AuthFlowLayout from 'components/AuthFlowLayout';
import { EmployerBenefitCTA } from 'components/EmployerBenefitCTA/EmployerBenefitCTA';
import { Loader } from 'components/Loader/Loader';
import { StepCountHeading } from 'components/StepCountHeading/StepCountHeading';
import { Button } from 'components/v2/Buttons/Button';
import { usePatient } from 'hooks';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import { QUIZ_ANSWER_QUERY, QUIZ_QUESTION_QUERY } from './BookingQuiz.graphql';
import { BookingQuizAnswer, BookingQuizQuestion } from './BookingQuiz.types';
import { OptionsList } from './components/OptionsList';
import { Question } from './components/Question';
import { BackButtonContainer } from './components/Question.styled';
import { QuizResult } from './components/QuizResult';

export const BookingQuiz = ({ history }: RouteComponentProps) => {
  const { isLoggedIn } = usePatient();
  const { status, loading: interstitialStatusLoading } = useInterstitialStatus({
    skip: !isLoggedIn
  });
  const insuranceStepCompleted = status?.insuranceDataCompleted || false;
  const params = new URLSearchParams(history.location.search);
  const currentQParam = params.get('q');
  const currentAParam = params.get('a');

  const [parentBookingQuizAnswerId, setParentBookingQuizAnswerId] = useState<string | null>(
    currentQParam || null
  );
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(currentAParam || null);
  const { data } = useQuery<{
    bookingQuizQuestions: BookingQuizQuestion;
  }>(QUIZ_QUESTION_QUERY, {
    variables: {
      parentBookingQuizAnswerId
    },
    onError: error => {
      BugTracker.notify(error, 'Could not fetch quiz question');
    }
  });
  const { data: selectedAnswerData, loading: answerLoading } = useQuery<{
    bookingQuizAnswer?: BookingQuizAnswer;
  }>(QUIZ_ANSWER_QUERY, {
    variables: {
      id: Number(selectedAnswerId)
    },
    skip: !selectedAnswerId,
    onError: error => {
      BugTracker.notify(error, 'Could not fetch quiz answer');
    }
  });

  const currentQuestion = data?.bookingQuizQuestions;
  const appointmentTypes = selectedAnswerData?.bookingQuizAnswer?.appointmentTypes || [];
  const category = selectedAnswerData?.bookingQuizAnswer?.category;

  let numberOfSteps = 5;

  if (isLoggedIn) {
    numberOfSteps = 4;
  }

  if (insuranceStepCompleted) {
    numberOfSteps = 3;
  }

  function handleResponse(quizAnswer: BookingQuizAnswer) {
    analytics.track(analytics.EVENTS.APPOINTMENT_QUIZ_RESPONDED, {
      appointment_category: currentQuestion?.category,
      question_text: currentQuestion?.text,
      question_id: currentQuestion?.id,
      option_text_selected: quizAnswer.text,
      option_id_selected: quizAnswer.id
    });
    if (quizAnswer.appointmentTypeIds.length > 0) {
      history.push(
        pageUrl.booking.bookingQuiz({
          type: 'answer',
          id: quizAnswer.id
        })
      );
    } else {
      history.push(
        pageUrl.booking.bookingQuiz({
          type: 'question',
          id: quizAnswer.id
        })
      );
    }
  }

  useEffect(() => {
    analytics.page(analytics.PAGES.APPOINTMENT_BOOKING_QUIZ);
  }, []);

  useEffect(() => {
    if (`${parentBookingQuizAnswerId}` !== currentQParam) {
      setParentBookingQuizAnswerId(currentQParam || null);
    }
  }, [parentBookingQuizAnswerId, currentQParam]);

  useEffect(() => {
    if (`${selectedAnswerId}` !== currentAParam) {
      setSelectedAnswerId(currentAParam || null);
    }
  }, [selectedAnswerId, currentAParam]);

  // for logged in users wait for interstitial status
  if (isLoggedIn && interstitialStatusLoading) {
    return null;
  }

  return (
    <AuthFlowLayout
      title={null}
      renderAboveTitleContent={() => {
        return (
          <>
            {!isLoggedIn && <EmployerBenefitCTA />}
            <StepCountHeading currentStep={1} totalSteps={numberOfSteps} />
          </>
        );
      }}
      renderMainContent={() => {
        if (!currentQuestion || answerLoading) {
          return <Loader container />;
        }

        if (appointmentTypes.length > 0) {
          return (
            <QuizResult
              appointmentTypes={appointmentTypes}
              category={category}
              isLoggedIn={isLoggedIn}
            />
          );
        }

        return (
          <div>
            <Question text={currentQuestion.text} category={currentQuestion.category} />
            <OptionsList
              options={currentQuestion.childBookingQuizAnswers}
              large={!currentQuestion.parentBookingQuizAnswerId}
              onSelect={handleResponse}
              isLoggedIn={isLoggedIn}
            />
            {currentQuestion.category && (
              <BackButtonContainer>
                <Button
                  label="Back"
                  fullWidth
                  size="lg"
                  category="secondary"
                  onClick={() => history.goBack()}
                />
              </BackButtonContainer>
            )}
          </div>
        );
      }}
    />
  );
};
