import { ApolloError, useQuery } from '@apollo/client';

import useHomeMarket from 'hooks/useHomeMarket';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { STATUS } from '../screens/InterstitialModal/InterstitialModal.graphql';
import {
  InterstitialStatusResponse,
  Status
} from '../screens/InterstitialModal/InterstitialModal.types';

export const useInterstitialStatus = (opts?: {
  skip?: boolean;
}): { status: Status | null; loading: boolean; error?: ApolloError } => {
  const { id, vios } = useHomeMarket();
  const { data, loading, error } = useQuery<InterstitialStatusResponse>(STATUS, {
    skip: vios || opts?.skip,
    onError: err => {
      BugTracker.notify(err, 'Failed to fetch interstitial status');
    }
  });

  return {
    status: !data || !id ? null : { ...data.interstitialStatus },
    loading,
    error
  };
};
