import { useEffect, useState, useRef } from 'react';

import { usePatient } from 'hooks';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';

export const useInsuranceStepCompletedOnInitialLoad = () => {
  const { isLoggedIn } = usePatient();
  const { status } = useInterstitialStatus({ skip: !isLoggedIn });
  const completedStepsSet = useRef(false);
  const [insuranceFinishedOnInitialLoad, setInsuranceFinishedOnInitialLoad] = useState(false);

  useEffect(() => {
    if (completedStepsSet.current === false && status) {
      const completed = Boolean(status.insuranceDataCompleted);
      setInsuranceFinishedOnInitialLoad(completed);
      completedStepsSet.current = true;
    }
  }, [status]);

  return insuranceFinishedOnInitialLoad;
};
