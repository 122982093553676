// eslint-disable-file
// @ts-nocheck

import { Moment, Moment as TMoment } from 'moment-timezone';

// Wrapper for "optional" or "nullable" values.
// Usage:
// const mightBeNull: Maybe<string> = null or 'value'
export type Maybe<T> = null | undefined | T;

export interface MobileInterface {
  mobile?: boolean;
}

export interface Lab {
  name: string;
  id: string;
  timeZone?: string;
  vios?: boolean;
}

export interface UploadFile {
  name: string;
  uuid: string;
}

export interface PatientsMembership {
  id: string;
  membershipId: string;
  employer: boolean;
  employerPhone?: string;
  employerName?: string;
  createdAt: string;
  membership?: {
    name: string;
    id: string;
    documentUrl?: string;
    description?: string;
    hidePartnerClinics?: string;
    employerId?: number;
  };
  membershipRules?: Array<{
    code: string;
    id: string;
    discountId: string;
  }>;
}

export enum AuthProvider {
  EmailPassword = 'aws_cognito_email_password',
  Google = 'google_sso',
  Apple = 'apple_sso',
  Medtronic = 'medtronic_sso',
  Disney = 'epic_sso'
}

export interface Patient {
  id?: string;
  name?: string;
  firstName: string;
  lastName: string;
  gender: string;
  pronoun?: string;
  birthday?: string;
  genderIdentity?: GenderIdentity;
  phone: string;
  email: string;
  password?: string;
  cognitoId?: string;
  authComms?: boolean;
  lab?: Lab;
  confirmed: boolean;
  authProviders: AuthProvider[];
  sendSmsAppointmentNotification?: boolean;
  address?: {
    id: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
  };
  google?: {
    id: number;
    token: string;
  };
  apple?: {
    token: string;
  };
  patientMembership?: PatientsMembership;
  submittedInsuranceCard?: boolean;
  submittedID?: boolean;
  preferredTimezone?: string;
  identifier?: string;
  createdAt?: string;
  patientTrustees?: PatientTrustee[];
  patientPharmacy?: {
    id: string;
    name?: string;
    phone?: string;
  };
  mfaEnabled?: boolean;
}

export type PatientTrustee = {
  id: string;
  name: string;
  phone: string;
  relationship: string;
  state: string;
  email: string;
  preferredContacts: string[];
};

export interface Address {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export interface Clinic {
  id: number;
  name: string;
  address: string;
  patientFacing: boolean;
  timeZone: string; // 'Eastern Time (US & Canada)'
  imageUrl?: string;
  lab: Lab;
}

type AppointmentCategoryName =
  | 'Non-discount code applicable Fertility'
  | 'LGBTQ'
  | 'Fertility'
  | 'Gyn'
  | 'Coaching';

/* ===== Bookable Products ===== */
export type AppointmentCategory = {
  id: string;
  name: AppointmentCategoryName;
  description: string;
};

export interface AppointmentType {
  id: string;
  name: string;
  description: string;
  rateCents: number;
  videoLink: string | null;
  virtual: boolean | null;
  virtualFert: boolean | null;
  locations?: Array<Clinic>;
  category: AppointmentCategory;
  channel: AppointmentTypeChannelType;
  shouldChargeAtBooking?: boolean;
  minuteDuration: number;
  hourDuration: number;
  validateProviderLicense?: string;
}

type AppointmentTypeChannelType = 'phone' | 'video' | 'in_person';

export interface AppointmentPackage {
  id: string;
  name: string;
  description: string;
  rateCents: number;
}

export interface Membership {
  id: string;
  description: string;
  name: string;
  rateCents: number;
  employer?: boolean;
}

export type PartnerClinicDetails = {
  name: string;
  address: string;
  distance: number;
  tier: string;
  city: string;
  state: string;
  contactName: string | null;
  contactEmail: string | null;
};

export type AppointmentProduct<
  TAppointmentType = AppointmentType,
  TMembership = Membership,
  TRescheduleAppointment = Appointment
> =
  | { type: 'appointment'; data: TAppointmentType }
  | { type: 'package'; data: { id: string; name?: string } }
  | { type: 'membership'; data: TMembership }
  | { type: 'reschedule_appointment'; data: TRescheduleAppointment & { name?: string } }
  | { type: 'package_appointment'; data: TAppointmentType }
  | { type: 'enterprise_membership'; data: { name?: string } };

export interface TimeSlot {
  id: string;
  location?: Clinic;
  videoLink?: string;
  startTime: string; // "2018-11-03T08:00:00-04:00"
  endTime: string;
  timeZone: string; // "Eastern Time (US & Canada)"
  lab?: Lab;
  utcStartTime?: TMoment;
  licensedProviderName?: string;
}

export type Days = Array<{
  timeslots: Appointment[]; // eslint-disable-line
  date: Record<string, unknown>;
}>;

export interface DayObject {
  timeslots: Appointment[]; // eslint-disable-line
  date: Record<string, unknown>;
  numKey: number;
}

export interface WeeksById {
  [key: string]: {
    days: Days;
    string: string;
    yearWeek: number;
  };
  // @ts-ignore
  string: string;
  // @ts-ignore
  yearWeek: number;
}

export interface AppointmentList {
  weeks: string[];
  weeksById: WeeksById;
}

/* ===== User Appointments/ User Packages ===== */

/**
 * A record belonging to a user of an appointment that they have booked.
 */
export interface Appointment {
  id: string;
  patientPackageId: number;
  appointmentType: AppointmentType;
  location: Clinic;
  startTime: string; // "2018-11-03T08:00:00-04:00"
  endTime: string;
  timeZone: string; // "Eastern Time (US & Canada)"
  cancelable: boolean; // If the user is allowed to cancel this appointment
  reschedulable: boolean; // If the user is allowed to reschedule this appointment
  providerName: string;
  intakeFormRequired: boolean;
  videoLink?: string;
}

/**
 * An appointment in a package that is available to the user to book
 */
export interface PatientPackageAppointment {
  id: string;
  appointmentTypeId: number;
  name: string;
  description: string | null;
  virtual: boolean | null;
  videoLink: boolean | null;
  locations: Array<Clinic>; // Is this the correct type?
}

/**
 * A package that has a cycle such ass egg freezing or ivf
 */
export interface Package {
  id: number;
  name: string;
  description: string;
  rateCents: number;
  discountRateCents: number;
  quantity: number;
}

/**
 * A package that the user has booked.
 * Contains a list of the kinds of appointments they can book,
 * and a list of appointments they have already consumed/booked from the package
 */
export interface PatientPackage {
  id: number;
  name: string;
  description: string;
  rateCents: number;
  quantity: number;
  bookableAppointments: Array<PatientPackageAppointment>;
  previouslyBookedAppointments: Array<Appointment>;
  appointmentPackage: AppointmentPackage;
}

export type PurchasedProduct =
  | { type: 'appointment'; data: Appointment }
  | { type: 'package'; data: PatientPackage }
  | { type: 'package_appointment'; data: PatientPackageAppointment };

// Third party
export interface StripeToken {
  id: string;
  object: string;
  bank_account?: Record<string, unknown>;
  card?: Record<string, unknown>;
  client_ip: string;
  created: number;
  livemode: boolean;
  type: string;
  used: boolean;
}

export interface ValidPromo {
  code: string;
  subtotalCents: number;
  discountCents: number;
  totalCents: number;
}

export interface StripeTokenResponse {
  token?: StripeToken;
  error?: Error;
}

/* ------------ Intake Form Data ------------ */

/* ------------------------------------------ */

export const formFieldDataTypes = [
  'string',
  'text',
  'boolean',
  'selection',
  'select',
  'multiple_selections',
  'date',
  'integer',
  'height',
  'email'
] as const;
export type FormFieldDataType = typeof formFieldDataTypes[number];

export const fieldGroupDataTypes = ['selection', 'multiple_selections', 'strings', 'boolean_pair'];
export type FieldGroupDataType = typeof fieldGroupDataTypes[number];

export interface FormSubheader {
  id: string;
  title: string;
  position: number;
  type: 'FormSubheader';
}

export interface FormField {
  id: string;
  title: string;
  position: number;
  dataType: FormFieldDataType;
  options: Array<string>;
  type: 'FormField';
  required: boolean | null;
}

export interface FormSection {
  title: string;
  active: boolean;
  type: 'FormSection';
  formElements: Array<FormSubheader | FormField | FieldGroup>;
  fieldGroups: Array<FieldGroup>;
  fieldGroupsMap: {
    [id: string]: FieldGroup;
  };
  position: number;
}

export interface FieldGroup {
  id: string;
  title: string;
  dataType: FieldGroupDataType;
  formFields: Array<FormField>;
  position: number;
  type: 'FieldGroup';
}

export enum FormConditionLogicOperator {
  AND = 'and',
  OR = 'or'
}

export interface FormConditions {
  value: string;
  conditionalFormElementId: number;
  dependencyFormElementId: number;
  logicOperator?: FormConditionLogicOperator | null;
}

export interface FormConditionWithAnswer {
  renderingCondition: FormConditions;
  answerOnDependency: string | undefined;
}

export interface IntakeForm {
  id: string;
  title: string;
  formSections: Array<FormSection>;
  conditionalFormElements: Array<FormConditions> | null;
}

export interface Answer {
  id: string;
  data: string;
  dataArray: Array<string> | null;
  formElementId: number;
  formElementTitle: string;
}

/* ----------- Daily Instructions ----------- */

/* ------------------------------------------ */

export interface DailyInstructionData {
  todaysInstructions: DailyInstruction;
}

export interface Drug {
  id: number;
  amount: string;
  dailyRecurrence: number;
  name: string;
  timeOfDay: 'AM' | 'PM';
}

export interface DailyInstruction {
  id: number | string;
  dayNumber: number;
  drugs: Array<Drug>;
  instructions: string;
  updatedAt: string;
  date?: string;
  dateLabel?: string;
  acknowledged?: boolean;
  haveIntercourse?: boolean;
}

/* -------------- Patient ToDos ------------- */

/* ------------------------------------------ */

export interface PatientToDo {
  id: string;
  todoId: number;
  title: string;
  description: string;
  order?: number | null;
  completed: boolean;
  linkUrl?: string | null;
  buttonText?: string | null;
  headingText?: string | null;
  category?: string; //should be one of the values from models/transitions/todo.rb
  createdAt: string;
}

export const isPatientToDo = (obj: PatientToDo): boolean => {
  return (
    typeof obj.id !== 'undefined' &&
    typeof obj.todoId !== 'undefined' &&
    typeof obj.title !== 'undefined' &&
    typeof obj.description !== 'undefined' &&
    typeof obj.order !== 'undefined' &&
    typeof obj.completed !== 'undefined'
  );
};

/**
 * Patient Consents
 */
export interface ConsentMetaData {
  html: string;
  id: string;
  name: string;
}

/**
 * Period Tracking
 */
export interface Vital {
  periodDate: string | null;
  nextPeriodDate: string | null;
  daysBetweenCycles: number | null;
  id: string | null;
}

// TODO: Needs type assertions
// export type UserConfirmationState = 'unconfirmed' | 'confirmed';
export type SignUpError =
  | { type: 'UserNameExists' }
  | { type: 'Unknown' }
  | { type: 'InvalidPhoneNumber' };
export type ConfirmationError =
  | { type: 'InvalidCode' }
  | { type: 'ExpiredCode' }
  | { type: 'Unknown' };
export type LoginError =
  | { type: 'Unconfirmed' }
  | { type: 'UnconfirmedWithResendLimitReached' }
  | { type: 'InvalidCredentials' }
  | { type: 'LimitExceededException' }
  | { type: 'MfaRequired' }
  | { type: 'Unknown' }
  | { type: 'WrongMfaCode' }
  | { type: 'MfaCodeExpired' }
  | { type: 'MfaBlocked' };
export type GoogleAuthError =
  | { type: 'UserNotFound' }
  | { type: 'UserAlreadyExistsAsEmail' }
  | { type: 'FailedToFindOnLoad' }
  | { type: 'UserHasNotSignedUp' };
export type AppleAuthError = {
  type: 'UserHasNotSignedUp' | 'UserAlreadyExistsAsEmail';
  email?: string;
};
export type ProfileImageError = { type: 'UnableToLoadImage' };
export type InsuranceCardError = { type: 'UnableToLoadFront' } | { type: 'UnableToLoadBack' };
export type IDError = { type: 'UnableToLoadFront' };

export type InsuranceCardSide = 'front' | 'back';
export type IDSide = 'front';

export type SignUpAddress = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

export type SignUpProps = {
  firstName: string;
  lastName: string;
  preferredName?: string;
  gender: string;
  pronoun: string;
  genderIdentity: string | null;
  birthdate: string;
  phone: string;
  email: string;
  password?: string;
  labId: number;
  event?: string;
  idtoken?: string;
  referralSource: string;
  referralSourceDetails: string;
  address: SignUpAddress;
  preferredTimezone: string;
  sendSmsAppointmentNotification: boolean;
  sendMarketingEmail: boolean;
};

export interface ResendCodeStatus {
  // TODO: Needs type assertions
  // status: null | 'loading' | 'success';
  status: string | null;
  error?: string | null;
}

export interface PatientState {
  userRefreshFailed: boolean;
  loading: boolean;
  isLoggedIn: boolean | undefined;
  // TODO: Needs type assertions
  // confirmationState: UserConfirmationState | null;
  confirmationState: string | null;
  resendConfirmationCode: ResendCodeStatus | null;
  patient: Patient;
  loginError: LoginError | null;
  loginErrorMetaData: string | null;
  signUpError: SignUpError | null;
  googleAuthError: GoogleAuthError | null;
  appleAuthError: AppleAuthError | null;
  confirmationError: ConfirmationError | null;
  profileImage?: string;
  profileImageError: ProfileImageError | null;
  googleAuthInitialize: boolean;
  insuranceCardErrors: {
    front?: InsuranceCardError;
    back?: InsuranceCardError;
  } | null;
  idErrors: {
    front?: IDError;
  } | null;
}

export interface InitializationState {
  googleAuth: boolean;
}

export type TemporaryClinic = {
  id: 'MIDTOWN' | 'WESTWOOD';
  name: string;
  address: {
    name: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
  };
};

export type Day = {
  timeslots: Array<TimeSlot>;
  date?: Moment;
};

export type WeekById = {
  days: Array<string>;
  daysById: {
    [day: string]: Day;
  };
  string: string;
  yearWeek: string;
};

export type NormalizedTimeSlots = {
  weeks: Array<string>;
  weeksById: {
    [weekKey: string]: WeekById;
  };
};

export interface PatientMembership {
  patient: {
    id: string;
    patientMembership: {
      id: string;
      employer?: boolean;
      membershipRules: Array<{
        code: string;
        id: string;
        discountId: string;
      }>;
    };
  };
}

// 🥖
export interface Renderable {
  type: 'copy' | 'strong' | 'list';
  copy?: string;
  items?: string[];
}

// 🥯
export interface RenderableItem {
  name: string;
  body: Renderable[];
}

// Messaging
export interface MessageType {
  id: number;
  read: boolean;
  body: string;
  fromPatient: boolean;
  createdAt: string;
  senderPhotoUrl?: string;
  senderName?: string;
  senderRole?: string;
  attachments: AttachmentType[];
}

export interface AttachmentType {
  id: number;
  fileName: string;
  file: string;
  isPatient: boolean;
  messageId: string;
  uuid: string;
}

export enum MessageCategory {
  admin = 'admin',
  medical = 'medical',
  billing = 'billing',
  tech = 'tech',
  employerBenefit = 'employer-benefit'
}

export interface ConversationType {
  assignedToUserName?: string;
  assignedToUserPhotoUrl?: string;
  assignedToUserRole?: string;
  category: MessageCategory;
  id: string;
  lastMessageTimestamp?: string;
  messages?: Array<MessageType>;
  patientId: string;
  preview?: string;
  status: string;
  subject: string;
  unreadCount: number;
}

export type EnvironmentType = 'production' | 'staging' | 'development' | 'uat' | 'qa';

export interface ObJourneyData {
  finished: boolean;
  expectedDeliveryDate?: Date;
  deliveryDate?: Date;
}

export type MomentTimeZone =
  | 'Hawaii'
  | 'Alaska'
  | 'Pacific Time (US & Canada)'
  | 'Mountain Time (US & Canada)'
  | 'Arizona'
  | 'Central Time (US & Canada)'
  | 'Eastern Time (US & Canada)'
  | 'UTC'
  | 'Atlantic Standard (Puerto Rico)';

export type GenderIdentity =
  | 'female'
  | 'male'
  | 'female_to_male'
  | 'male_to_female'
  | 'non_conforming'
  | 'genderqueer'
  | 'other'
  | 'not_answer'
  | 'transgender_non_binary'
  | 'non_binary'
  | 'two_spirit'
  | 'questioning'
  | 'not_answer'
  | 'unknown';

export type NullableString = string | null | undefined;

type AuthenticationErrorCode =
  | 'NotAuthorizedException'
  | 'MfaRequired'
  | 'NotAuthorizedException'
  | 'UserNotConfirmedException'
  | 'UserNotConfirmedWithLimitExceededException'
  | 'WrongMfaCode'
  | 'MfaCodeExpired'
  | 'MfaBlocked';

export interface AuthenticatePatientResponse {
  authenticatePatient: {
    succeeded: boolean;
    errorCode?: AuthenticationErrorCode;
    errorMetaData?: string | null;
    accessToken?: string | null;
    refreshToken?: string | null;
    idToken?: string | null;
  };
}

export interface AuthenticatePatientRequestVariables {
  email: string;
  password: string;
  otp?: string;
}
